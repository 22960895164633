import FileUploader from "components/FileUploader";
import Table from "components/Table";
import {
  useFiberProperties,
  useFiberPropertiesDispatch,
} from "contexts/FiberPropertiesContext";
import styled from "styled-components";
import { useDisclosure } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "asset/submit-button-background.png";
import ProgressModal from "components/ProgressModal";
import { YARN_QUANTITY_RANGE_AND_OFFSET } from "components/Table/constants";
import { detectFileLanguage, extractYarnProperties } from "util/XlsxUtil";
import { XlsxType } from "types/apiTypes";

const MainWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Submit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 150px;
  border-radius: 14px;
  background: url(${backgroundImage});
  & > span {
    color: #fafafa;
    font-family: S-Core Dream;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

const Home = () => {
  const { data } = useFiberProperties();
  const dispatch = useFiberPropertiesDispatch();
  const {
    isOpen,
    onOpen: openModal,
    onOpenChange,
    onClose: closeModal,
  } = useDisclosure();
  const navigate = useNavigate();

  const isNumeric = (str: string) => {
    return !isNaN(parseFloat(str)) && isFinite(str as any);
  };
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!Array.isArray(data)) {
      alert("Data is not in the correct format.");
      return;
    }

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const range = YARN_QUANTITY_RANGE_AND_OFFSET[i];

      if (item === "" || item === undefined || item === null) continue;

      if (range && (typeof item !== "string" || !isNumeric(item))) {
        alert(
          `Index ${i} is not in the correct format or contains non-numeric values.`
        );
        return;
      }

      if (range) {
        const num = parseFloat(item);
        if (num < range.min || num > range.max) {
          alert(
            `Value at index ${i} is out of range. It should be between ${range.min} and ${range.max}.`
          );
          return;
        }
      }
    }
    openModal();
  };
  return (
    <MainWrapper onSubmit={onSubmit}>
      <FileUploader
        onFileUpload={async (file) => {
          if (!file) throw new Error("file No!!!");
          try {
            const { isKorean, isError, errorMessage } =
              await detectFileLanguage(file);
            if (isError) {
              console.error("Error detecting file language:", errorMessage);
              return;
            }
            const language = isKorean ? "KOR" : "ENG";

            // Creating FormData to hold the file and language
            const formData = new FormData();
            formData.append("file", file, file.name);
            formData.append("language", language);

            console.log(file.name);

            // Sending the request to the server
            const response = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/api/parseXlsx/`,
              {
                headers: {
                  "Content-Disposition": "form-data",
                  filename: file.name,
                },
                method: "POST",
                body: formData,
              }
            );

            if (!response.ok) {
              console.error("Error uploading file:", await response.text());
              return;
            }

            const responseObject = (await response.json()) as XlsxType;
            const resultYarnProperties = extractYarnProperties(responseObject);
            console.log(resultYarnProperties);
            dispatch({ type: "UPDATE_DATA", payload: resultYarnProperties });
          } catch (error: any) {
            console.error(
              "Unexpected error occurred:",
              error.message || "Unknown Error"
            );
          }
        }}
      />
      <Table kind="fiberResult" />
      <Submit type="submit">
        <span>주어진 데이터로 역공학 모델 실행</span>
      </Submit>
      <ProgressModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        onComplete={() => {
          closeModal();
          navigate("/result");
        }}
      />
    </MainWrapper>
  );
};

export default Home;
