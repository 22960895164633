import { Progress } from "@nextui-org/react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Texts = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 12px;
`;

const Label = styled.span`
  color: #1f2224;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

interface Props {
  value: number;
  label: string;
}

const ProgressBar = ({ value, label }: Props) => {
  return (
    <Wrapper>
      <Texts>
        <Label>{label}</Label>
      </Texts>
      <Progress aria-label="loading..." value={value} />
    </Wrapper>
  );
};

export default ProgressBar;
