import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { recipeType } from "types/apiTypes";

type DataType = {
  processInput: recipeType["data"][0]["processInput"];
  fiberOutput: recipeType["data"][0]["fiberOutput"];
};
type recipeResultType = {
  data: DataType[]; // 두칸짜리 배열
};

const recipeResultsContext = createContext<recipeResultType | undefined>(
  undefined
);

type recipeResultsResultsActionType =
  | {
      type: "UPDATE_DATA";
      payload: DataType[];
    }
  | {
      type: "UPDATE_ITEM_AT_INDEX";
      index: number;
      value: DataType;
    };
const recipeResultsDispatchContext = createContext<
  React.Dispatch<recipeResultsResultsActionType> | undefined
>(undefined);

const recipeResultsReducer = (
  state: recipeResultType,
  action: recipeResultsResultsActionType
): recipeResultType => {
  switch (action.type) {
    case "UPDATE_DATA":
      return { ...state, data: action.payload };
    case "UPDATE_ITEM_AT_INDEX":
      const updatedData = [...state.data];
      updatedData[action.index] = action.value;
      return { ...state, data: updatedData };
    default:
      throw new Error(
        `Unhandled action type: ${
          (action as recipeResultsResultsActionType).type
        }`
      );
  }
};

type RecipeResultsContextProviderProps = {
  children: ReactNode;
};

export const RecipeResultsProvider: React.FC<
  RecipeResultsContextProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(recipeResultsReducer, { data: [] });

  useEffect(() => {
    if (
      state.data.length === 0 &&
      sessionStorage.getItem("recipeResults") !== null &&
      JSON.parse(sessionStorage.getItem("recipeResults") as string).length !== 0
    ) {
      dispatch({
        type: "UPDATE_DATA",
        payload: JSON.parse(sessionStorage.getItem("recipeResults") as string),
      });
      console.log(sessionStorage);
    }
    sessionStorage.setItem("recipeResults", JSON.stringify(state.data));
  }, [state.data, state.data.length]);

  return (
    <recipeResultsDispatchContext.Provider value={dispatch}>
      <recipeResultsContext.Provider value={state}>
        {children}
      </recipeResultsContext.Provider>
    </recipeResultsDispatchContext.Provider>
  );
};

export const useRecipeResults = () => {
  const context = useContext(recipeResultsContext);
  if (!context) {
    throw new Error(
      "useRecipeResults must be used within a RecipeResultsProvider"
    );
  }
  return context;
};

export const useRecipeResultsDispatch = () => {
  const context = useContext(recipeResultsDispatchContext);
  if (!context) {
    throw new Error(
      "useRecipeResultsDispatch must be used within a RecipeResultsProvider"
    );
  }
  return context;
};
