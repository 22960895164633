export function extractFirstWordFromCamelCase(camelCaseString: string): string {
  // 대문자를 찾아서 공백으로 치환합니다.
  const words = camelCaseString
    .replace(/([A-Z])/g, " $1")
    .trim()
    .split(" ");
  return words[0].toLowerCase(); // 첫 번째 단어를 반환합니다.
}

export const getLastSegmentFromURL = (): string => {
  const pathname = window.location.pathname; // 현재 페이지의 경로를 가져옵니다.
  const segments = pathname.split("/"); // '/'를 기준으로 경로를 분리합니다.
  return segments.pop() || ""; // 마지막 부분을 추출합니다.
};
