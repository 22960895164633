import React, { useState } from "react";
import { Language } from "./types";
import { TABLE_STRUCTURE, TRANSLATIONS } from "./constants";
import "./table.css";
import { Tabs, Tab } from "@nextui-org/react";
import styled from "styled-components";
import { getNumberOfConceptions } from "./util";
import PropertyEditor from "components/PropertyEditor";
import TableInputRows from "./TableInputRows";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-right: 100px;
  & table {
    overflow: visible;
  }
`;

interface BaseTableProps {
  kind: "processInput" | "fiberResult";
}

interface WritableTableProps extends BaseTableProps {
  kind: "fiberResult";
  readonly?: false;
  valueData?: never;
}

interface ReadOnlyTableProps extends BaseTableProps {
  readonly: true;
  valueData: string[];
}

type Props = WritableTableProps | ReadOnlyTableProps;

const Table = ({ kind, readonly, valueData }: Props) => {
  let curRenderingIdx = 0;
  const [language, setLanguage] = useState<Language>("en");
  return (
    <Wrapper>
      <Tabs
        aria-label="Options"
        selectedKey={language}
        onSelectionChange={(key) => setLanguage(key as Language)}
      >
        <Tab key="en" title="English"></Tab>
        <Tab key="ko" title="한국어"></Tab>
      </Tabs>
      <table>
        <thead>
          <tr>
            <th>
              {language === "ko"
                ? (TRANSLATIONS["CONTENT"] as string)
                : "CONTENT"}
            </th>
            <th colSpan={2}>
              {language === "ko"
                ? (TRANSLATIONS["CONCEPTION"] as string)
                : "CONCEPTION"}
            </th>
            <th>
              {language === "ko" ? (TRANSLATIONS["UNIT"] as string) : "UNIT"}
            </th>
            <th style={{ width: "100px" }}>
              {language === "ko"
                ? (TRANSLATIONS["SETTING VALUE"] as string)
                : "SETTING VALUE"}
            </th>
            {!readonly && (
              <>
                <th />
                <th>
                  {language === "ko" ? (TRANSLATIONS["MIN"] as string) : "MIN"}
                </th>
                <th>
                  {language === "ko" ? (TRANSLATIONS["MAX"] as string) : "MAX"}
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {TABLE_STRUCTURE[kind].map((item) => (
            <React.Fragment key={item.content}>
              {item.conceptions.map((conception, index) => (
                <React.Fragment key={conception.title}>
                  {conception.subconceptions ? (
                    conception.subconceptions.map((subItem, subIndex) => (
                      <tr key={subItem.title}>
                        {index === 0 && subIndex === 0 && (
                          <td rowSpan={getNumberOfConceptions(item)}>
                            {language === "ko"
                              ? (
                                  TRANSLATIONS[item.content] as Record<
                                    string,
                                    string
                                  >
                                )[item.content]
                              : item.content}
                          </td>
                        )}
                        {subIndex === 0 && (
                          <td rowSpan={conception.subconceptions.length}>
                            {language === "ko"
                              ? (
                                  TRANSLATIONS[item.content] as Record<
                                    string,
                                    string
                                  >
                                )[conception.title]
                              : conception.title}
                          </td>
                        )}
                        <td>
                          {language === "ko"
                            ? (
                                TRANSLATIONS[item.content] as Record<
                                  string,
                                  string
                                >
                              )[subItem.title]
                            : subItem.title}
                        </td>
                        <td>{subItem.unit}</td>
                        <td style={{ position: "relative" }}>
                          {readonly ? (
                            <span>{valueData[curRenderingIdx++]}</span>
                          ) : (
                            <PropertyEditor idx={curRenderingIdx} />
                          )}
                        </td>
                        {!readonly && (
                          <TableInputRows curRenderingIdx={curRenderingIdx++} />
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      {index === 0 && (
                        <td rowSpan={getNumberOfConceptions(item)}>
                          {language === "ko"
                            ? (
                                TRANSLATIONS[item.content] as Record<
                                  string,
                                  string
                                >
                              )[item.content]
                            : item.content}
                        </td>
                      )}
                      <td colSpan={conception.subconceptions ? 1 : 2}>
                        {language === "ko"
                          ? (
                              TRANSLATIONS[item.content] as Record<
                                string,
                                string
                              >
                            )[conception.title]
                          : conception.title}
                      </td>
                      <td>{conception.unit}</td>
                      <td style={{ position: "relative" }}>
                        {readonly ? (
                          <span>{valueData[curRenderingIdx++]}</span>
                        ) : (
                          <PropertyEditor idx={curRenderingIdx} />
                        )}
                      </td>
                      {!readonly && (
                        <TableInputRows curRenderingIdx={curRenderingIdx++} />
                      )}
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Table;
