import {
  useFiberProperties,
  useFiberPropertiesDispatch,
} from "contexts/FiberPropertiesContext";
import styled from "styled-components";

interface Prop {
  idx: number;
}

const InputWrapper = styled.div`
  height: 16px;
`;

const Input = styled.input`
  background: transparent;
  border: 1px solid black;
  width: 100px;
  text-align: center;
`;

const PropertyEditor = ({ idx }: Prop) => {
  const { data } = useFiberProperties();
  const dispatch = useFiberPropertiesDispatch();

  return (
    <InputWrapper>
      <Input
        value={data[idx]}
        onChange={(e) => {
          dispatch({
            type: "UPDATE_ITEM_AT_INDEX",
            index: idx,
            value: e.target.value,
          });
        }}
        type="text"
      />
    </InputWrapper>
  );
};
export default PropertyEditor;
