// https://najm-eddine-zaga.medium.com/react-render-multiple-layouts-with-react-router-dom-v6-7a42bd984acf
import Home from "pages/Home";
import { ReactNode } from "react";
import { renderRoutes } from "./generate-routes";
import MainLayout from "layouts/MainLayout";
import { FiberPropertiesProvider } from "contexts/FiberPropertiesContext";
import BriefResult from "pages/BriefResult";
import DetailResult from "pages/DetailResult";
import FullScreenLayout from "layouts/FullScreenLayout";
import { RecipeResultsProvider } from "contexts/RecipeResultsContext";
import { Outlet } from "react-router-dom";

export const routes: mainRouteType[] = [
  {
    layout: (
      <RecipeResultsProvider>
        <FiberPropertiesProvider>
          <MainLayout />
        </FiberPropertiesProvider>
      </RecipeResultsProvider>
    ),
    routes: [
      {
        name: "home",
        title: "main page",
        component: <Home />,
        path: "/",
      },
    ],
  },
  {
    layout: <Outlet />,
    routes: [
      {
        name: "detail",
        title: "detail page",
        component: <DetailResult />,
        path: "/detail",
      },
    ],
  },
  {
    layout: (
      <RecipeResultsProvider>
        <FiberPropertiesProvider>
          <FullScreenLayout />
        </FiberPropertiesProvider>
      </RecipeResultsProvider>
    ),
    routes: [
      {
        name: "result",
        title: "result page",
        component: <BriefResult />,
        path: "/result",
      },
    ],
  },
];

export interface mainRouteType {
  layout: ReactNode;
  routes: routeType[];
}

export interface routeType {
  layout?: ReactNode;
  name: string;
  title: string;
  component: ReactNode;
  path: string;
  routes?: routeType[];
}

export const Routes = renderRoutes(routes);
