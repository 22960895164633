import styled from "styled-components";
import Tooltip from "components/Tooltip";
import SpinningProgressImage from "asset/spninng-progress.png";

const Wrapper = styled.div`
  width: 564px;
  height: 748px;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const TOOLTIP_POSITIONS: {
  left: number;
  top: number;
  tailDirection:
    | "left"
    | "topLeft"
    | "topRight"
    | "right"
    | "bottomRight"
    | "bottomLeft";
}[] = [
  { left: 343, top: 125, tailDirection: "topLeft" },
  { left: 0, top: 605, tailDirection: "bottomRight" },
  { left: 240, top: 581, tailDirection: "left" },
  { left: 350, top: 383, tailDirection: "left" },
  { left: 350, top: 447, tailDirection: "left" },
];

interface Props {
  dataList: string[];
}

const ProcessVisualizer = ({ dataList }: Props) => {
  return (
    <Wrapper>
      <img src={SpinningProgressImage} alt="spinning progress" />
      {TOOLTIP_POSITIONS.map(({ left, top, tailDirection }, idx) => {
        const data = dataList[idx];
        return (
          <Tooltip
            bgColor="#d0d0d0"
            left={left}
            top={top}
            tailDirection={tailDirection}
            key={idx}
          >
            {data}
          </Tooltip>
        );
      })}
    </Wrapper>
  );
};

export default ProcessVisualizer;
