import { Button } from "@nextui-org/react";
import Table from "components/Table";
import styled from "styled-components";
import { ReactComponent as RefreshIcon } from "asset/refresh.svg";
import { ReactComponent as ZoomInIcon } from "asset/zoom-in.svg";
import ProgressModal from "components/ProgressModal";
import { useRecipeResults } from "contexts/RecipeResultsContext";
import { fiberOutputObjToArray } from "components/Table/util";
import { useState } from "react";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
const MyInputSection = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 50%;
`;
const ResultSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 50%;
`;
const ResultTables = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const Title = styled.h2`
  color: #000;
  text-align: center;
  font-family: S-Core Dream;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
`;
const TableAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: calc(100% - 225px);
`;

const SubTitle = styled.h3`
  color: #000;
  text-align: center;
  font-family: S-Core Dream;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const RowOfResultTable = styled.div`
  display: flex;
  gap: 52px;
  align-items: center;
  width: 100%;
`;

const BriefResult = () => {
  const { data } = useRecipeResults();

  const [isModalOpen, setIsModalOpen] = useState(false);

  console.log(isModalOpen);

  return (
    <Wrapper>
      <MyInputSection
        onSubmit={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
      >
        <Title>내가 입력한 값</Title>
        <Table kind="fiberResult" readonly={false} />
        <Button
          size="md"
          color="primary"
          startContent={<RefreshIcon />}
          type="submit"
        >
          이 값으로 재시도
        </Button>
      </MyInputSection>
      <ResultSection>
        <Title>근사 값 리스트</Title>
        <ResultTables>
          {data.map((data, idx) => {
            const stringifiedData = Object.fromEntries(
              Object.entries(data.processInput).map(([key, value]) => [
                key,
                value.toString(),
              ])
            );
            return (
              <>
                <RowOfResultTable>
                  <TableAndTitle>
                    <SubTitle>결과 {idx + 1}</SubTitle>
                    <Table
                      kind="fiberResult"
                      readonly={true}
                      valueData={fiberOutputObjToArray(data.fiberOutput)}
                    />
                  </TableAndTitle>
                  <Button
                    size="md"
                    color="primary"
                    endContent={<ZoomInIcon />}
                    onClick={() => {
                      window.open(
                        `/detail?${new URLSearchParams(stringifiedData)}`,
                        "_blank"
                      );
                    }}
                  >
                    이 결과 상세보기
                  </Button>
                </RowOfResultTable>
              </>
            );
          })}
        </ResultTables>
      </ResultSection>
      <ProgressModal
        isOpen={isModalOpen}
        onOpenChange={(isOpen) => {}}
        onComplete={() => {
          setIsModalOpen(false);
        }}
      />
    </Wrapper>
  );
};

export default BriefResult;
