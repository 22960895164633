import {
  useFiberProperties,
  useFiberPropertiesDispatch,
} from "contexts/FiberPropertiesContext";
import { ReactComponent as PlusIcon } from "asset/plus.svg";
import { ReactComponent as MinusIcon } from "asset/minus.svg";
import { YARN_QUANTITY_RANGE_AND_OFFSET } from "./constants";
import { isValidRange } from "./util";
import styled from "styled-components";

const PlusMinusBtns = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 4px;
`;

interface Props {
  curRenderingIdx: number;
}
const TableInputRows = ({ curRenderingIdx }: Props) => {
  const { data } = useFiberProperties();
  const dispatch = useFiberPropertiesDispatch();
  const handlePlusClick = (idx: number) => {
    let newValue: string;

    if (isValidRange(parseFloat(data[idx])+ YARN_QUANTITY_RANGE_AND_OFFSET[idx].offset, idx)) {
      newValue = (
        parseFloat(data[idx]) + YARN_QUANTITY_RANGE_AND_OFFSET[idx].offset
      )
        .toFixed(2)
        .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
    } else {
      newValue = String(YARN_QUANTITY_RANGE_AND_OFFSET[idx].min);
    }

    dispatch({
      type: "UPDATE_ITEM_AT_INDEX",
      index: idx,
      value: String(newValue),
    });
  };

  const handleMinusClick = (idx: number) => {
    let newValue: string;

    if (isValidRange(parseFloat(data[idx])- YARN_QUANTITY_RANGE_AND_OFFSET[idx].offset, idx)) {
      newValue = (
        parseFloat(data[idx]) - YARN_QUANTITY_RANGE_AND_OFFSET[idx].offset
      )
        .toFixed(2)
        .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
    } else {
      newValue = String(YARN_QUANTITY_RANGE_AND_OFFSET[idx].max);
    }

    dispatch({
      type: "UPDATE_ITEM_AT_INDEX",
      index: idx,
      value: String(newValue),
    });
  };
  return (
    <>
      <td>
        <PlusMinusBtns>
          <PlusIcon onClick={() => handlePlusClick(curRenderingIdx)} />
          <MinusIcon onClick={() => handleMinusClick(curRenderingIdx)} />
        </PlusMinusBtns>
      </td>
      <td>{YARN_QUANTITY_RANGE_AND_OFFSET[curRenderingIdx].min}</td>
      <td>{YARN_QUANTITY_RANGE_AND_OFFSET[curRenderingIdx].max}</td>
    </>
  );
};

export default TableInputRows;
