// contexts/FiberPropertiesContext.tsx
import React, {
  createContext,
  useContext,
  ReactNode,
  useReducer,
  useEffect,
} from "react";

type DataType = string;
type FiberPropertiesType = {
  data: DataType[];
};

const FiberPropertiesContext = createContext<FiberPropertiesType | undefined>(
  undefined
);

type FiberPropertiesActionType =
  | { type: "UPDATE_DATA"; payload: DataType[] }
  | {
      type: "UPDATE_ITEM_AT_INDEX";
      index: number;
      value: DataType;
    };
const FiberPropertiesDispatchContext = createContext<
  React.Dispatch<FiberPropertiesActionType> | undefined
>(undefined);

const fiberPropertiesReducer = (
  state: FiberPropertiesType,
  action: FiberPropertiesActionType
): FiberPropertiesType => {
  switch (action.type) {
    case "UPDATE_DATA":
      return { ...state, data: action.payload };
    case "UPDATE_ITEM_AT_INDEX":
      // 배열의 n번째 요소 갱신
      const updatedData = [...state.data];
      updatedData[action.index] = action.value;
      return { ...state, data: updatedData };
    default:
      throw new Error(
        `Unhandled action type: ${(action as FiberPropertiesActionType).type}`
      );
  }
};

type FiberPropertiesProviderProps = {
  children: ReactNode;
};

export const FiberPropertiesProvider: React.FC<
  FiberPropertiesProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(fiberPropertiesReducer, { data: [] });

  useEffect(() => {
    if (
      state.data.length === 0 &&
      sessionStorage.getItem("fiberProperties") !== null &&
      JSON.parse(sessionStorage.getItem("fiberProperties")!).length !== 0
    ) {
      dispatch({
        type: "UPDATE_DATA",
        payload: JSON.parse(sessionStorage.getItem("fiberProperties")!),
      });
      console.log(sessionStorage);
    }
    sessionStorage.setItem("fiberProperties", JSON.stringify(state.data));
  }, [state.data, state.data.length]);

  return (
    <FiberPropertiesDispatchContext.Provider value={dispatch}>
      <FiberPropertiesContext.Provider value={state}>
        {children}
      </FiberPropertiesContext.Provider>
    </FiberPropertiesDispatchContext.Provider>
  );
};

export const useFiberProperties = () => {
  const context = useContext(FiberPropertiesContext);
  if (!context) {
    throw new Error(
      "useFiberProperties must be used within a FiberPropertiesProvider"
    );
  }
  return context;
};

export const useFiberPropertiesDispatch = () => {
  const context = useContext(FiberPropertiesDispatchContext);
  if (!context) {
    throw new Error(
      "useFiberPropertiesDispatch must be used within a FiberPropertiesProvider"
    );
  }
  return context;
};
