import { TableContentStructure } from "./types";

const INPUT_TABLE_STRUCTURE: TableContentStructure[] = [
  {
    content: "CHIP",
    conceptions: [
      { title: "CHIP (A/B)", unit: "kind" },
      { title: "Luster (A:B)", unit: "" },
    ],
  },
  {
    content: "EXTRUDER",
    conceptions: [
      { title: "Heater Temp #1 (A/B)", unit: "℃" },
      { title: "Heater Temp #2 (A/B)", unit: "℃" },
      { title: "Heater Temp #3 (A/B)", unit: "℃" },
      { title: "Heater Temp #4 (A/B)", unit: "℃" },
      { title: "Press. (A/B)", unit: "Mpa" },
    ],
  },
  {
    content: "HM BOILER",
    conceptions: [
      { title: "Spinbeam Temp.", unit: "℃" },
      { title: "Manifold Temp (A/B)", unit: "℃" },
    ],
  },
  {
    content: "POLYMER M/P",
    conceptions: [
      { title: "Capacity (A/B)", unit: "cc/rev" },
      { title: "Ratio (A/B)", unit: "" },
      { title: "Motor (A/B)", unit: "rpm" },
      { title: "Quantity (A/B)", unit: "g/min.end" },
    ],
  },
  {
    content: "SPINNERET",
    conceptions: [
      { title: "Hole CS", unit: "Type" },
      { title: "Hole Number", unit: "Nu." },
      { title: "Length", unit: "mm" },
      { title: "Depth", unit: "mm" },
      { title: "Sand", unit: "mesh" },
    ],
  },
  {
    content: "QUENCHING",
    conceptions: [
      { title: "Type", unit: "" },
      { title: "Quantity (A/B)", unit: "m/sec" },
      { title: "Solidificiation Temp", unit: "℃" },
    ],
  },
  {
    content: "FINISH OIL",
    conceptions: [
      { title: "Brand", unit: "name" },
      {
        title: "JET / ROLLER",
        subconceptions: [
          { title: "Concentration", unit: "%" },
          { title: "Rpm", unit: "rpm" },
          { title: "Convergency", unit: "m" },
        ],
      },
    ],
  },
  {
    content: "INTERACE",
    conceptions: [
      { title: "Migration Press.", unit: "kg/cm²" },
      { title: "Interace Press.", unit: "kg/cm²" },
    ],
  },
  {
    content: "GODET R/O",
    conceptions: [
      {
        title: "GR1",
        subconceptions: [
          { title: "Speed", unit: "m/min" },
          { title: "Temp", unit: "℃" },
        ],
      },
      {
        title: "GR2",
        subconceptions: [
          { title: "Speed", unit: "m/min" },
          { title: "Temp", unit: "℃" },
        ],
      },
    ],
  },
  {
    content: "WINDER",
    conceptions: [
      {
        title: "F/R",
        subconceptions: [
          { title: "Speed", unit: "m/min" },
          { title: "Angle", unit: "Φ / °" },
          { title: "contact press#1", unit: "kg/cm²" },
          { title: "contact press#2", unit: "kg/cm²" },
        ],
      },
      { title: "Doffing Time", unit: "min" },
      { title: "Net Wt.", unit: "g" },
    ],
  },
  {
    content: "etc",
    conceptions: [
      {
        title: "PAPER TUBE",
        unit: "color",
      },
      { title: "T3 TENSION", unit: "g" },
      {
        title: "pack press",
        subconceptions: [
          {
            title: "M/P before",
            unit: "kg/cm²",
          },
          { title: "M/P after", unit: "kg/cm²" },
        ],
      },
    ],
  },
];
const OUTPUT_TABLE_STRUCTURE: TableContentStructure[] = [
  {
    content: "YARN QUNATITY",
    conceptions: [
      { title: "TENACITY", unit: "g/de²" },
      { title: "ELONGATION", unit: "%" },
      {
        title: "THERMAL STRESS",
        subconceptions: [
          { title: "avg. temp.", unit: "℃" },
          { title: "avg. str.", unit: "g" },
        ],
      },
      { title: "UNEVENNESS", unit: "%" },
      { title: "YARN_NUM", unit: "denier" },
      { title: "CRYSTALLINITY", unit: "%" },
      { title: "DEGRADATION_TEMP", unit: "℃" },
      { title: "MELT_TEMP", unit: "℃" },
      { title: "GLASS_TRAN_TEMP", unit: "℃" },
    ],
  },
];

export const TABLE_STRUCTURE = {
  processInput: INPUT_TABLE_STRUCTURE,
  fiberResult: OUTPUT_TABLE_STRUCTURE,
};

export const TRANSLATIONS: Record<string, string | Record<string, string>> = {
  CONTENT: "구분",
  CONCEPTION: "변수명",
  UNIT: "단위",
  "SETTING VALUE": "설정값",
  MIN: "최솟값",
  MAX: "최댓값",
  CHIP: {
    CHIP: "원료",
    "CHIP (A/B)": "칩 (A/B)",
    "Luster (A:B)": "배합 (A:B)",
  },
  EXTRUDER: {
    EXTRUDER: "용융방사",
    "Heater Temp #1 (A/B)": "압출기 #1 온도 (A/B)",
    "Heater Temp #2 (A/B)": "압출기 #2 온도 (A/B)",
    "Heater Temp #3 (A/B)": "압출기 #3 온도 (A/B)",
    "Heater Temp #4 (A/B)": "압출기 #4 온도 (A/B)",
    "Press. (A/B)": "압력 (A/B)",
  },
  "HM BOILER": {
    "HM BOILER": "폴리머 보온",
    "Spinbeam Temp.": "스핀빔 온도",
    "Manifold Temp (A/B)": "매니폴드 온도 (A/B)",
  },
  "POLYMER M/P": {
    "POLYMER M/P": "미러링펌프",
    "Capacity (A/B)": "용량 (A/B)",
    "Ratio (A/B)": "비율 (A/B)",
    "Motor (A/B)": "모터 (A/B)",
    "Quantity (A/B)": "토출량 (A/B)",
  },
  SPINNERET: {
    SPINNERET: "압출",
    "Hole CS": "Hole CS 유형",
    "Hole Number": "Hole Number",
    Length: "길이",
    Depth: "직경",
    Sand: "금속분말 입도",
  },
  QUENCHING: {
    QUENCHING: "냉각고화",
    Type: "냉각방식",
    "Quantity (A/B)": "풍속 (A/B)",
    "Solidificiation Temp": "고화온도",
  },
  "FINISH OIL": {
    "FINISH OIL": "오일 부착",
    Brand: "유제명",
    "JET / ROLLER": "오일 도포 타입",
    Concentration: "오일 농도",
    Rpm: "오일 분사 속도",
    Convergency: "냉각장",
  },
  INTERACE: {
    INTERACE: "실 꼬기",
    "Migration Press.": "Migration 압력",
    "Interace Press.": "Interace 압력",
  },
  "GODET R/O": {
    "GODET R/O": "고뎃롤러",
    GR1: "고뎃롤러1",
    GR2: "고뎃롤러2",
    Speed: "속도",
    Temp: "온도",
  },
  WINDER: {
    WINDER: "와인더",
    "F/R": "프릭션롤러",
    Speed: "속도",
    Angle: "각도",
    "contact press#1": "접압#1",
    "contact press#2": "접압#2",
    "Doffing Time": "도핑시간",
    "Net Wt.": "순중량",
  },
  etc: {
    etc: "기타",
    "PAPER TUBE": "페이퍼 튜브",
    "T3 TENSION": "T3 텐션",
    "pack press": "팩 압력",
    "M/P before": "M/P before",
    "M/P after": "M/P after",
  },
  "YARN QUNATITY": {
    "YARN QUNATITY": "물성",
    TENACITY: "사인장강도",
    ELONGATION: "사인장신도",
    "THERMAL STRESS": "열응력",
    "avg. temp.": "평균온도",
    "avg. str.": "평균강도",
    UNEVENNESS: "사불균제도",
    YARN_NUM: "번수",
    CRYSTALLINITY: "고분자결정화도",
    DEGRADATION_TEMP: "열분해온도",
    MELT_TEMP: "용융온도",
    GLASS_TRAN_TEMP: "유리전이온도",
  },
};

export const YARN_QUANTITY_RANGE_AND_OFFSET = [
  { min: 3.3, max: 4.5, offset: 0.05 },
  { min: 20, max: 40, offset: 1 },
  { min: 80, max: 120, offset: 1 },
  { min: 4, max: 22, offset: 1 },
  { min: 1.9, max: 5.3, offset: 0.1 },
  { min: 35.0, max: 36.5, offset: 0.1 },
  { min: 28, max: 88, offset: 1 },
  { min: 295, max: 360, offset: 1 },
  { min: 135, max: 170, offset: 1 },
  { min: 45, max: 65, offset: 1 },
];
