import styled from "styled-components";
import ProcessVisualizer from "components/ProcessVisualizer";
import Table from "components/Table";
import { Button } from "@nextui-org/react";
import { generateXlsx } from "util/XlsxUtil";
import { ProcessInputObjToArray } from "components/Table/util";

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  padding: 100px;
  gap: 100px;
  justify-content: space-between;
`;

const ProcessVisualizerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TableWrapper = styled.div`
  width: 50%;
`;

const DetailResult = () => {
  const params = new URLSearchParams(window.location.search);
  const sbt = parseFloat(params.get("sbt")!);
  const r1s = parseFloat(params.get("r1s")!);
  const r2s = parseFloat(params.get("r2s")!);
  const r2t = parseFloat(params.get("r2t")!);
  const dr = parseFloat(params.get("dr")!);
  const frs = parseFloat(params.get("frs")!);
  const curPageData = ProcessInputObjToArray({ sbt, r1s, r2s, r2t, dr, frs });
  const visualizerData = [
    `spinbeam temp : ${sbt.toFixed(0)}`,
    `godet roller1 speed : ${r1s.toFixed(0)}`,
    `dr : ${dr.toFixed(2)}`,
    `godet roller2 speed : ${r2s.toFixed(0)}`,
    `godet roller2 temp : ${r2t.toFixed(0)}`,
  ];
  return (
    <Wrapper>
      <ProcessVisualizerWrapper>
        <ProcessVisualizer dataList={visualizerData} />
        <Button color="primary" onClick={() => generateXlsx(curPageData)}>
          엑셀 파일 다운로드
        </Button>
      </ProcessVisualizerWrapper>
      <TableWrapper>
        <Table kind="processInput" readonly valueData={curPageData} />
      </TableWrapper>
    </Wrapper>
  );
};

export default DetailResult;
