import * as XLSX from "xlsx";
import workbookInfo from "./workbookInfo.json";
import { XlsXResultYarnPropertiesKey, XlsxType } from "types/apiTypes";
import { ExistingCells, UserInputCellAddress } from "types/workbookInfoType";

interface FileLanguageDetectionResult {
  isKorean: boolean;
  isError: boolean;
  errorMessage?: string;
}

export const detectFileLanguage = (
  file: File
): Promise<FileLanguageDetectionResult> => {
  return new Promise((resolve) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(
          (e.target as FileReader).result as ArrayBuffer
        );
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Assuming that the sheet might contain rows of any[] type.
        const sheetData: any[][] = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          raw: true,
        });

        for (const row of sheetData) {
          if (!Array.isArray(row)) continue; // Skip if the row is not an array
          for (const cell of row) {
            if (typeof cell === "string" && /[가-힣]/.test(cell)) {
              resolve({ isKorean: true, isError: false });
              return;
            }
          }
        }
        resolve({ isKorean: false, isError: false });
      };

      reader.onerror = () => {
        resolve({
          isKorean: false,
          isError: true,
          errorMessage: "Error reading file",
        });
      };

      reader.readAsArrayBuffer(file);
    } catch (error: any) {
      resolve({
        isKorean: false,
        isError: true,
        errorMessage: error.message || "Unknown Error",
      });
    }
  });
};

export function extractYarnProperties(responseObject: XlsxType): Array<string> {
  const yarnPropertiesKeys: XlsXResultYarnPropertiesKey[] = [
    "Tenacity",
    "Elongation",
    "TS_temp",
    "TS_str",
    "Unevenness",
    "Yarn_Num",
    "Crystal",
    "Degra_Temp",
    "Melt_Temp",
    "Glass_Tran_Temp",
  ];

  return yarnPropertiesKeys.map((key) =>
    responseObject[key] ? String(responseObject[key]) : ""
  );
}

export function generateXlsx(userInputs: string[]) {
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();

  for (const sheetName of Object.keys(workbookInfo)) {
    const sheetInfo = workbookInfo[sheetName as keyof typeof workbookInfo];

    const newSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([], {
      cellStyles: true,
    });

    // 원본 셀 정보 모두 복사 (테두리, 스타일, 값 등)
    for (const cellAddress in sheetInfo) {
      if (Object.prototype.hasOwnProperty.call(sheetInfo, cellAddress)) {
        if (cellAddress.startsWith("!")) {
          // 특수 속성
          newSheet[cellAddress] = sheetInfo[cellAddress as "!ref"];
        } else {
          // 일반 셀
          newSheet[cellAddress] = {
            ...sheetInfo[cellAddress as ExistingCells],
          };
        }
      }
    }

    // F5에서 F55까지 사용자 입력 값으로 채우기
    const startRow = 5; // F5부터 시작
    for (let i = 0; i < 50; i++) {
      const cellAddress = `F${startRow + i}`;
      if (newSheet[cellAddress]) {
        newSheet[cellAddress].v = userInputs[i]; // 해당 셀의 값만 변경
      } else {
        newSheet[cellAddress] = { v: userInputs[i] }; // 새로운 셀 생성
      }
    }

    // 새로운 워크시트를 workbook에 추가
    XLSX.utils.book_append_sheet(workbook, newSheet, sheetName);
  }

  // 엑셀 파일 생성 및 다운로드
  XLSX.writeFile(workbook, "output.xlsx");
}
