import { recipeType } from "types/apiTypes";
import { YARN_QUANTITY_RANGE_AND_OFFSET } from "./constants";
import { TableContentStructure } from "./types";

export const getNumberOfConceptions = (item: TableContentStructure) => {
  const numberOfAllSubConceptions = item.conceptions.reduce((acc, cur) => {
    return acc + (cur.subconceptions?.length || 0);
  }, 0);
  const numberOfConceptionsWithoutSubConceptions = item.conceptions.reduce(
    (acc, cur) => {
      return acc + (cur.subconceptions ? 0 : 1);
    },
    0
  );

  return numberOfAllSubConceptions + numberOfConceptionsWithoutSubConceptions;
};

export function isValidRange(value: number, idx: number) {
  return (
    value >= YARN_QUANTITY_RANGE_AND_OFFSET[idx].min &&
    value <= YARN_QUANTITY_RANGE_AND_OFFSET[idx].max
  );
}

export const fiberOutputObjToArray = (
  obj: recipeType["data"][0]["fiberOutput"]
) => {
  const {
    tenacity,
    elongation,
    ts_avg_temp,
    ts_avg_str,
    unevenness,
    yarn_num,
    crystallinity,
    degradation_temp,
    melt_temp,
    glass_tran_temp,
  } = obj;
  return [
    tenacity.toFixed(2),
    elongation.toFixed(2),
    ts_avg_temp.toFixed(2),
    ts_avg_str.toFixed(2),
    unevenness.toFixed(2),
    yarn_num.toFixed(2),
    crystallinity.toFixed(2),
    degradation_temp.toFixed(2),
    melt_temp.toFixed(2),
    glass_tran_temp.toFixed(2),
  ];
};

export const ProcessInputObjToArray = (
  obj: recipeType["data"][0]["processInput"]
) => {
  const { sbt, r1s, r2s, r2t, frs, dr } = obj;
  return [
    "PLA",
    "",
    "252",
    "257",
    "252",
    "252",
    "160",
    sbt.toFixed(2),
    "250",
    "1.2",
    "100",
    "11.9",
    "1559",
    "O",
    "12",
    "0.52",
    "0.28",
    "#30",
    "cross",
    "20",
    "0.4",
    "#1308",
    "15",
    "17",
    "1.6",
    "1.5",
    "3",
    r1s.toFixed(2),
    "90",
    r2s.toFixed(2),
    r2t.toFixed(2),
    frs.toFixed(2),
    "7.5",
    "12",
    "15",
    "1:38:00",
    "500g",
    "",
    "",
    "",
    "",
    `dr : ${dr.toFixed(2)}`,
  ];
};
