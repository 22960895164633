import lablogo from "asset/logo.png";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const FOOTER_HEIGHT = 113;
const Footer = () => {
  return (
    <StyledFooter>
      <img src={lablogo} alt="ese lab logo" />
      <span>powered by KNU ESE lab</span>
    </StyledFooter>
  );
};
const Wrap = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  @media (min-width: 1800px) {
    justify-content: center;
    align-items: center;
  }
`;
const Section = styled.section`
  padding: 8px;
  padding-bottom: ${FOOTER_HEIGHT}px;
  width: 100%;
  height: 100%;
  @media (min-width: 1800px) {
    width: 1500px;
    border: 1px solid #18181b;
    margin-bottom: 40px;
    border-radius: 12px;
    padding-bottom: 8px;
  }
`;

const StyledFooter = styled.footer`
  height: ${FOOTER_HEIGHT}px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 18.5px 30px;
`;

const MainLayout = () => {
  return (
    <Wrap>
      <Section>
        <Outlet />
      </Section>
      <Footer />
    </Wrap>
  );
};

export default MainLayout;
