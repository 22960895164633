import styled from "styled-components";
import { Button, Modal, ModalBody, ModalContent } from "@nextui-org/react";
import modalImage from "asset/processing-modal-image.png";
import ProgressBar from "components/ProgressBar";
import { ReactComponent as CloseIcon } from "asset/close-circle.svg";
import { useFiberProperties } from "contexts/FiberPropertiesContext";
import { useEffect, useState, useRef } from "react";
import { recipeType } from "types/apiTypes";
import { useRecipeResultsDispatch } from "contexts/RecipeResultsContext";

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 120px;
  & > img {
    width: 600px;
    height: 400px;
  }
`;

const StyledProgressBarGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  justify-content: center;
`;

interface Props {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onComplete: () => void;
}

const ProgressModal = ({ isOpen, onOpenChange, onComplete }: Props) => {
  const dispatch = useRecipeResultsDispatch();
  const { data } = useFiberProperties();
  const [progressValue, setProgressValue] = useState(0);
  const [status, setStatus] = useState("");
  const [controller, setController] = useState<AbortController>(
    new AbortController()
  );
  const [signal] = useState(controller.signal);

  const [
    tenacity,
    elongation,
    ts_avg_temp,
    ts_avg_str,
    unevenness,
    yarn_num,
    crystallinity,
    degradation_temp,
    melt_temp,
    glass_tran_temp,
  ] = data;

  const onFirstRecipeDone = (response: recipeType) => {
    console.log(response);
    dispatch({
      type: "UPDATE_ITEM_AT_INDEX",
      index: 0,
      value: {
        processInput: response.data[0].processInput,
        fiberOutput: response.data[0].fiberOutput,
      },
    });
    setProgressValue((prev) => prev + 50);
    setStatus("첫번째 레시피 계산 완료");
  };

  const onSecondRecipeDone = (response: recipeType) => {
    console.log(response);
    dispatch({
      type: "UPDATE_ITEM_AT_INDEX",
      index: 1,
      value: {
        processInput: response.data[0].processInput,
        fiberOutput: response.data[0].fiberOutput,
      },
    });
    setProgressValue((prev) => prev + 50);
    setStatus("두번째 레시피 계산 완료");
  };

  useEffect(() => {
    if (isOpen) {
      const queryParams = new URLSearchParams({
        tenacity,
        elongation,
        ts_avg_temp,
        ts_avg_str,
        unevenness,
        yarn_num,
        crystallinity,
        degradation_temp,
        melt_temp,
        glass_tran_temp,
      });

      const firstResponse = fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/get-first-recipe?${queryParams}`,
        {
          signal,
          method: "GET",
        }
      ).then((response) => response.json()) as Promise<recipeType>;
      firstResponse.then(onFirstRecipeDone);

      const secondResponse = fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/get-second-recipe?${queryParams}`,
        {
          signal,
          method: "GET",
        }
      ).then((response) => response.json()) as Promise<recipeType>;
      secondResponse.then(onSecondRecipeDone);
    }
  }, [isOpen]);

  useEffect(() => {
    if (progressValue >= 100) {
      setProgressValue(0);
      setStatus("");
      onComplete();
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      hideCloseButton
      isDismissable={false}
      size="3xl"
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <StyledModalBody>
              <img src={modalImage} alt="fiber processing" />
              <StyledProgressBarGroup>
                <ProgressBar value={progressValue} label={status} />
                <Button
                  color="danger"
                  startContent={<CloseIcon />}
                  onClick={() => {
                    controller.abort();
                    setProgressValue(0);
                    closeModal();
                  }}
                >
                  취소
                </Button>
              </StyledProgressBarGroup>
            </StyledModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ProgressModal;
