import { flattenDeep } from "lodash";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import { mainRouteType, routeType } from "routes";

const generateFlattenRoutes: FuncType = (routes) => {
  if (!routes) return [];
  return flattenDeep(
    routes.map(({ routes: subRoutes, ...rest }) => [
      rest,
      generateFlattenRoutes(subRoutes),
    ])
  );
};

export const renderRoutes = (mainRoutes: mainRouteType[]) => {
  const Routes = () => {
    const layouts = mainRoutes.map(({ layout: Layout, routes }, index) => {
      const subRoutes = generateFlattenRoutes(routes);

      return (
        <Route key={index} element={Layout}>
          {subRoutes.map(({ component: Component, path, name }) => {
            return (
              Component &&
              path && <Route key={name} element={Component} path={path} />
            );
          })}
        </Route>
      );
    });
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };
  return Routes;
};

interface FuncType {
  (routes?: routeType[]): routeType[];
}
