import lablogo from "asset/logo.png";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const FOOTER_HEIGHT = 113;
const Footer = () => {
  return (
    <StyledFooter>
      <img src={lablogo} alt="ese lab logo" />
      <span>powered by KNU ESE lab</span>
    </StyledFooter>
  );
};
const Wrap = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  padding: 20px;
  @media (min-width: 1800px) {
    justify-content: center;
    align-items: center;
  }
`;

const StyledFooter = styled.footer`
  height: ${FOOTER_HEIGHT}px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding: 18.5px 30px;
`;

const FullScreenLayout = () => {
  return (
    <Wrap>
      <Outlet />
      <Footer />
    </Wrap>
  );
};

export default FullScreenLayout;
