import { PropsWithChildren } from "react";
import { ReactComponent as UnstyledTailUp } from "asset/tooltip-tail-up.svg";
import { ReactComponent as UnstyledTailDown } from "asset/tooltip-tail-down.svg";
import { ReactComponent as UnstyledTailLeft } from "asset/tooltip-tail-left.svg";
import { ReactComponent as UnstyledTailRight } from "asset/tooltip-tail-right.svg";
import styled from "styled-components";
import { extractFirstWordFromCamelCase } from "util/index";

const WRAPPER_VERTICAL_PADDING = 12;
const WRAPPER_HORIZONTAL_PADDING = 16;

interface WrapperProp {
  bgcolor: string;
  top: number;
  left: number;
}

interface TailProp {
  bgcolor: string;
}

interface TailPositionProp {
  width: string;
  height: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  justifycontent: string;
}

type TailDirection =
  | "bottomLeft"
  | "bottomRight"
  | "topLeft"
  | "topRight"
  | "left"
  | "right";

interface Props {
  bgColor: string;
  tailDirection: TailDirection;
  top: number;
  left: number;
  onClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
}

const Wrapper = styled.div<WrapperProp>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  display: flex;
  padding: ${WRAPPER_VERTICAL_PADDING}px ${WRAPPER_HORIZONTAL_PADDING}px;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid #eee;
  background: ${(props) => props.bgcolor};
`;

const TextBox = styled.div`
  color: #000;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

const TailPosition = styled.div<TailPositionProp>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: absolute;
  top: ${({ top }) => top && top + "px"};
  bottom: ${({ bottom }) => bottom && bottom + "px"};
  left: ${({ left }) => left && left + "px"};
  right: ${({ right }) => right && right + "px"};
  justify-content: ${(props) => props.justifycontent};
  padding: 0 10px;
  display: flex;
`;

const TailUp = styled(UnstyledTailUp)<TailProp>`
  fill: ${(props) => props.bgcolor};
`;
const TailDown = styled(UnstyledTailDown)<TailProp>`
  fill: ${(props) => props.bgcolor};
`;
const TailLeft = styled(UnstyledTailLeft)<TailProp>`
  fill: ${(props) => props.bgcolor};
`;
const TailRight = styled(UnstyledTailRight)<TailProp>`
  fill: ${(props) => props.bgcolor};
`;

const mapTailDirectionToTailPosition = (
  tailDirection: TailDirection
): TailPositionProp => {
  switch (tailDirection) {
    case "bottomLeft":
      return {
        width: "100%",
        height: "0",
        top: 40,
        justifycontent: "flex-start",
      };
    case "bottomRight":
      return {
        width: "100%",
        height: "0",
        top: 40,
        justifycontent: "flex-end",
      };
    case "topLeft":
      return {
        width: "100%",
        height: "0",
        top: -10,
        justifycontent: "flex-start",
      };
    case "topRight":
      return {
        width: "100%",
        height: "0",
        top: -10,
        justifycontent: "flex-end",
      };
    case "left":
      return {
        width: "40px",
        height: "100%",
        top: 10,
        left: -24,
        justifycontent: "center",
      };
    case "right":
      return {
        width: "40px",
        height: "100%",
        top: 10,
        right: -24,
        justifycontent: "center",
      };
    default:
      throw new Error(`Unknown tail direction: ${tailDirection}`);
  }
};

const renderTailWithDirection = (direction: string, bgColor: string) => {
  switch (direction) {
    case "top":
      return <TailUp bgcolor={bgColor} />;
    case "bottom":
      return <TailDown bgcolor={bgColor} />;
    case "left":
      return <TailLeft bgcolor={bgColor} />;
    case "right":
      return <TailRight bgcolor={bgColor} />;
    default:
      throw new Error(`Unknown direction: ${direction}`);
  }
};

const Tooltip = ({
  bgColor,
  tailDirection,
  top,
  left,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const direction = extractFirstWordFromCamelCase(tailDirection);
  return (
    <Wrapper bgcolor={bgColor} top={top} left={left} {...props}>
      <TextBox>{children}</TextBox>
      <TailPosition {...mapTailDirectionToTailPosition(tailDirection)}>
        {renderTailWithDirection(direction, bgColor)}
      </TailPosition>
    </Wrapper>
  );
};

export default Tooltip;
